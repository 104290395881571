<!--
 * @Descripttion: 个人中心
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-11-09 17:19:01
-->
<template>
  <wk-header ref="header"></wk-header>
  <el-main class="left-main" style="padding-top:0; padding-bottom: 0; position: relative;">
    <el-row :gutter="20" class="h100">
      <el-col :span="3" :offset="3" class="user-info left-main" style="position: fixed;">
        <div class="left-user">
          <img v-if="userInfo.portrait" :src="userInfo.portrait" :alt="userInfo.name" :title="userInfo.name" class="avatar user-img">
          <img v-else src="./../assets/img/demo.png" class="avatar">
          <p class="gray">{{userInfo.name}}</p>
        </div>
        <div class="left-tool-type">
          <p :class="index ==1 ? 'active' : ''" @click="index = 1"><user class="ico-svg" />用户信息</p>
          <p :class="index ==2 ? 'active' : ''" @click="index = 2"><star class="ico-svg" />我的收藏</p>
          <p @click="handleLoginOut"><switchButton class="ico-svg" />退出</p>
        </div>
      </el-col>
      <el-col :span="6" :offset="7" v-show="index == '1'">
        <div class="user-container">
          <h3>个人中心</h3>
          <p style="font-size: 18px;" class="mt20">用户信息</p>
          <p class="gray">用户名：</p>
          <el-input class="mt10" v-model="userInfo.name" ></el-input>
          <!-- <p class="gray mt20">头像</p>
          <el-upload
            action="https://jsonplaceholder.typicode.com/posts/"
            class="avatar-uploader"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            disabled
          >
            <img v-if="userInfo.portrait" :src="userInfo.portrait" :alt="userInfo.name"  class="avatar user-img" />
            <plus v-else class="avatar-uploader-icon" />
          </el-upload> -->
          <p class="gray">邮箱：</p>
          <el-input class="mt10" v-model="userInfo.email"></el-input>
        <p class="mt30">
          <el-button type="primary" class="w100" @click="handelOnSave">保存</el-button>
        </p>
        </div>
      </el-col>
      <el-col :span="12" :offset="7" v-show="index == '2'" class="left-main">
        <el-row :gutter="20">
        <el-col :span="12" v-for="item in collectToolsList" :key="item">
            <div class="tool-vertical-li tool-li" @click="handleDetail(item)">
              <div class="tool-li-title">
                <img class="tool-logo" v-if="item.propagatePic" :src="item.propagatePic">
								<img class="tool-logo" v-else>
                <div class="tool-title-content">
                  <h4 class="el-ellipsis">{{item.toolName}}</h4>
                  <p class="industry el-ellipsis" style="display:block">{{item.industryLabel}}</p>
                </div>
                <el-button size="mini" class="tool-btn-cancel" @click.stop="handleCancel(item.naturalKey)">取消收藏</el-button>
              </div>
              <div class="tool-desc el-ellipsis--l2">
                <p>{{item.description}}</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>

    </el-row>

  </el-main>
  <wk-footer></wk-footer>
</template>

<script>
import WkHeader  from '@/components/WKHeader'
import WkFooter from '@/components/WKFooter'
import {ElMessage, ElMessageBox } from 'element-plus'
// import { Plus } from '@element-plus/icons'
import {getAction,postAction} from '@/utils/http'
import global from '@/common/global'

export default {
  name: 'Mine',
  components: {
    WkHeader,
    WkFooter,
  },
  data() {
    return {
      index: 1,
      imageUrl: '',
      userInfo:{
        id:'',
        openid:'',
        unionid:'',
        portrait:'',
        sex:'',
        name:'',
        email:''
      },
      urls:{
        collectListByUser:'/wbpt/collection/tools/collectListByUser',
        updateUserInfo: '/wbpt/collection/tools/onUpdateUserInfo',
        removeCollectNew:'/wbpt/collection/tools/removeCollectNew'
      },
      collectToolsList:[],
      collectToolsTotal:0,
      queryParams:{
        pageNo: 1,
        pageSize: 8,
        createBy: '',
        collectionType:'01'
      },
      loading:false,//加载中
      expEmail:/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,/*邮箱正则表达式 */
    }
  },
  beforeMount() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.initScroll, true)
    })
  },
  beforeUnmount() {

    window.removeEventListener('scroll', this.initScroll, true)
  },
  created(){
    if(this.$ls.get("wikeHome_current_userInfo")){
      this.userInfo = this.$ls.get("wikeHome_current_userInfo");
      this.$ls.set("wikeHome_current_userInfo",this.userInfo,28*60*60*1000);//设置有效时间 28天
      this.queryParams.createBy = this.userInfo.id;
    }else{
      this.queryParams.createBy = '6aa31af061cb4da3835327a87af050b4';
    }
    this.initSelectToolsCollectList();
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload(file) {
      console.log(file)
      return true
    },

    handleCancel(naturalKey) {
      ElMessageBox.confirm(
        '确定取消收藏吗？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '关闭',
          type: 'info'
        }
      ).then( () => {
        this.removeCollect(naturalKey);
      }).catch( () => {
        ElMessage.info('放弃本操作')
      })
    },
    //初始化查询工具List
    initSelectToolsCollectList(){
      this.queryParams.pageNo = 1;
      this.collectToolsList = [];
      this.getToolsCollectList();
    },
    //查询工具收藏
    getToolsCollectList(){
      this.queryParams.collectionType = '01';

      getAction(this.urls.collectListByUser,this.queryParams).then(res=>{
        if(res.success){
          if(res.result.records){
            res.result.records.forEach(element => {
              if(element.industrys){
                let industryLabel = '';
                element.industrys.forEach(item => {
                  industryLabel += item.labelName + ' '
                });
                element.industryLabel = industryLabel.substr(0,industryLabel.length - 1)
              }
              this.collectToolsList.push(element);
            });
          }
          // console.log(res.result.records);
          // this.collectToolsList = res.result.records;
          this.collectToolsTotal = res.result.total;
        }else{
          ElMessage.error('查询收藏失败，请稍后重试！')
        }
      }).finally(()=>{
        this.loading = false;
      }).catch(err=>{
        ElMessage.error('查询收藏异常，请稍后重试！')
        console.log(err)
      });
    },
    //查看详情
    handleDetail(item) {
      this.$router.push({
        path: '/toolDetail',
        query: {
          toolId: item.naturalKey
        }
      })
    },
		//取消收藏
		removeCollect(naturalKey){
			let postData = {}
			postData.userId = ""
      if(this.$ls.get("wikeHome_current_userInfo")){
        postData.userId = this.$ls.get("wikeHome_current_userInfo").id;
        postData.key = naturalKey
        postAction(this.urls.removeCollectNew,postData).then(res=>{
          if(res.success){
            ElMessage({
              message: '取消收藏成功',
              type: 'success',
            });
            this.initSelectToolsCollectList();
          }else{
            ElMessage({
              message: '取消收藏失败,请稍后再试！',
              type: 'error',
            });
          }
        }).catch(()=>{
            ElMessage({
              message: '取消收藏异常，请稍后再试！',
              type: 'error',
            });
        });
      }else{
        ElMessage({
          message: '请先登录，再点击取消收藏',
          type: 'info',
        });
        this.$refs.header.onShowDialogLogin();
      }
		},
    /**
     * @Description: 滚动条的触底操作（距离底部50px时，开始加载下一页）
     * @Author: Niklaus
     * @Param:
     * @Return:
     * @Date: 2021-11-10 14:37:49
     */
    initScroll() {
      let scropHeight = document.documentElement.scropHeight || document.body.scrollHeight
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
      if(scropHeight - scrollTop - clientHeight <= 0 &&this.loading==false
        &&this.collectToolsTotal>this.collectToolsList.length) {
        this.queryParams.pageNo++;
        this.getToolsCollectList();
      }
    },
    //退出
    handleLoginOut() {
      ElMessageBox.confirm(
        '确定退出吗？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }
      ).then( () => {
          this.collectToolsList = [];
          this.collectToolsTotal = 0;
          this.userInfo = {};
          this.$refs.header.handleLoginOut(global.defaultIndexUrl);
      }).catch( (err) => {
        console.log(err);
        // ElMessage.info('放弃本操作!')
      })
    },
    //保存用户信息
    handelOnSave(){
      if(this.onCheckUserInfo()==false){
        return;
      }
      let obj={
        id:this.userInfo.id,
        name: this.userInfo.name,
        email:this.userInfo.email
      }
      console.log(JSON.stringify(obj));
      postAction(this.urls.updateUserInfo,obj).then(res=>{
        if(res.success){
          ElMessage({
            message: '保存成功',
            type: 'success',
          });
          this.$ls.set("wikeHome_current_userInfo",this.userInfo,28*60*60*1000);//设置有效时间 28天
        }else{
          ElMessage({
            message: res.message,
            type: 'error',
          });
        }
      }).catch(err=>{
        console.log(err);
      });
    },
    //校验用户录入信息
    onCheckUserInfo(){
      if(this.userInfo.name.trim()==''){
          ElMessage({
            message: '保存失败，用户名不能为空！',
            type: 'error',
          });
          return false;
      }
      if(this.userInfo.name.length>100){
          ElMessage({
            message: '保存失败，用户名过长！',
            type: 'error',
          });
          return false;
      }
      if(this.userInfo.email.length>40){
          ElMessage({
            message: '保存失败，邮箱过长！',
            type: 'error',
          });
          return false;
      }
      if(typeof this.userInfo.email !="undefined" && this.userInfo.email.trim()!=""){
          let regRes = this.expEmail.test(this.userInfo.email);
          if(!regRes){
            ElMessage({
              message: '邮箱格式不正确！',
              type: 'error',
            });
            return false;
          }
      }
      return true;
    },
    //下一个方法
  }
 }
</script>

<style scoped>
  .h100 {
    padding-left: 0;
    padding-right: 0;
  }
  .user-info {
    background-color: #F2F8FF;
    width: 100%;
     padding-left: 0 !important;
    padding-right: 0 !important;

  }
  .ico-svg {
    color: #1E87FF;
  }
  .active .ico-svg {
    color: white;
  }
  .user-img{
    border-radius: 50%;
  }
</style>